import React, { useState, useEffect, useContext } from 'react';
import placeDataStub from './placeDataStub';
import { PlaceData } from './GoogleMapsContextDataTypes';
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

type GoogleMapsContextType = {
  data: PlaceData;
  isLoaded: boolean;
}
export const GoogleMapsContext = 
  React.createContext<GoogleMapsContextType>({} as GoogleMapsContextType);
export const useGoogleMapsData = () => useContext(GoogleMapsContext);
const libraries: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = ["places"];
const fields = [
  "rating",
  "user_ratings_total",
  "url",
  "reviews",
  "name",
  "geometry",
  "address_components",
  "opening_hours",
  "formatted_phone_number",
];

type ProviderProps = {
  input: string;
  children?: React.ReactNode;
}
const GoogleMapsProvider: React.FC<ProviderProps> = ( p ) => {
  const [placeData, setPlaceData] = useState<PlaceData>(placeDataStub);
  const { isLoaded, loadError } = useLoadScript({ 
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries
  });

  useEffect(() => {
    if(loadError)
      console.log('Unable to load Google Maps API:', loadError);
  }, [loadError])

  useEffect(() => {
    console.log('Place data:', placeData);
  }, [placeData])

  return (
    <GoogleMapsContext.Provider value={{ data: placeData, isLoaded }}>
      <Loader isLoaded={isLoaded} setPlaceData={setPlaceData} />
      {/* {isLoaded ? <Search input={p.input} /> : null} */}
      {p.children}
    </GoogleMapsContext.Provider>
  );
}

type SearchProps = {
  input?: string;
}

type LoaderProps = {
  isLoaded: boolean;
  setPlaceData: React.Dispatch<React.SetStateAction<PlaceData>>;
}

const Loader: React.FC<LoaderProps> = ( p ) => {
  const { data, isLoaded } = useContext(GoogleMapsContext);
  useEffect(() => {
    if(!isLoaded)
      return;
    console.log('Maps API loaded');
    getDetails({ 
      placeId: process.env.REACT_APP_GOOGLE_MAPS_PLACE_ID as string,
      fields: fields 
    }).then((result) => {
      var data = result as google.maps.places.PlaceResult;
      console.log('Data retrieved:', data);
      p.setPlaceData(prev => {
        var update = structuredClone(prev);
        update.rating = data.rating ?? prev.rating;
        update.user_ratings_total = data.user_ratings_total ?? prev.user_ratings_total;
        update.url = data.url ?? prev.url;
        update.reviews = data.reviews ?? prev.reviews;
        update.name = data.name ?? prev.name;
        update.lat = data.geometry?.location?.lat() ?? prev.lat;
        update.lng = data.geometry?.location?.lng() ?? prev.lng;
        if(data.address_components) {
          update.address_components = [
            `${data.address_components[1].long_name} ${data.address_components[2].short_name} ${data.address_components[0].long_name}`,
            `${data.address_components[4].long_name}, ${data.address_components[5].short_name} ${data.address_components[7].long_name}`,
            `${data.address_components[6].long_name}`
          ] 
        }
        if(data.opening_hours?.weekday_text) { // todo: update formatted hours
          update.hours = data.opening_hours.weekday_text.map(day => day);
        }
        update.open = data.opening_hours ? data.opening_hours.open_now : prev.open;
        update.office_phone_number = data.formatted_phone_number ?? prev.service_phone_number;
        return update;
      });
    }).catch(err => {
      console.log('Google Maps Error:', err);
    });
  }, [isLoaded])

  return <></>;
}
const Search: React.FC<SearchProps> = ( p ) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    clearCache,
    init
  } = usePlacesAutocomplete({
    requestOptions: {

    },
    debounce: 300,
  });

  useEffect(() => {
    if(p.input)
      setValue(p.input);
  }, [p.input])

  useEffect(() => {
    if(data.length > 0)
      console.log('Search complete. Results:', data);
  }, [data])
  
  return (
    <></>
  );
}

export default GoogleMapsProvider;