import React, { LegacyRef, useRef, useEffect, useState, SyntheticEvent } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from 'modules/common';
import { useGoogleMapsData } from 'remote';
import styles from "./Nav.module.css";
import { FaMapMarkerAlt, FaPhoneAlt, FaClock } from 'react-icons/fa';


type NavProps = {
  id?: string;
  children?: React.ReactNode;
};

const Nav: React.FC<NavProps> = (props) => {
  const ref = useRef<HTMLDivElement>();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useGoogleMapsData();

  return (
    <div ref={ref as LegacyRef<HTMLDivElement>} className={styles.ctn}>
      <div className={styles.innerCtn}>
        <div className={styles.logo}>
          <em style={{ color: 'red' }}>KIEU</em>
          <em style={{ color: 'black' }}>AUTO</em>
        </div>
        <div className={styles.content} key={new Date().getTime()}>
          <div className={styles.item}>
            <FaMapMarkerAlt className={styles.icon}/>
            {data.address_components[0]} 
            <span className={styles.open} style={{ color: data.open ? 'grey' : 'red' }}>
              {`● ${data.open ? 'Open now' : 'Closed'}`}  
            </span>
          </div>
          <div className={styles.item}>
              <FaPhoneAlt className={styles.icon} />
              {data.service_phone_number}
          </div>
          <div className={styles.item}>
            {location.pathname === '/' && <Button label='Sales Inventory' className={styles.btn} onClick={(e) => navigate('/sales')}/>}
            {location.pathname === '/sales' && <Button label='Homepage' className={styles.btn} onClick={(e) => navigate('/')}/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav;