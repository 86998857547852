import React, { useContext, useRef, useState, useEffect } from 'react';
import styles from './ListingModal.module.css';
import { CgClose as CloseIcon } from 'react-icons/cg';
import ViewMedia from './ViewMedia/ViewMedia';
import { ListingType, SalesAPI } from 'remote';
import { TbGauge as OdoIcon } from 'react-icons/tb';
import { BiCar as CarIcon } from 'react-icons/bi';
import { MdOutlineSubtitles as DescIcon } from 'react-icons/md';
import { BsTelephone as PhoneIcon } from 'react-icons/bs';
import { AiOutlineMail as MailIcon } from 'react-icons/ai';
import { BsQuestionCircle as QuestionIcon } from 'react-icons/bs';
import { BeatLoader as LoadingSpinner } from 'react-spinners';
import { useGoogleMapsData, useData } from 'remote';
import { Button } from 'modules/common';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  selected: string | null;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
}

function ListingModal(p: Props) {
  const { data } = useGoogleMapsData();
  const { DataFunctions } = useData();
  const [listing, setListing] = useState<ListingType[]>([]);

  const formRef = useRef<HTMLFormElement>(null);
  const [msgSent, setMsgSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    setListing(p.selected ? DataFunctions.getListingById(p.selected) : [])
  }, [p.selected])

  async function onSubmit(e: React.SyntheticEvent) {
    if(!formRef.current || !reRef.current)
      return;
    if(formRef.current.reportValidity()) {
      const token = await reRef.current.executeAsync();
      reRef.current.reset();
      setIsLoading(true);
      SalesAPI.sendInquiry(
        formRef.current.msg.value,
        listing[0].title,
        formRef.current.email.value,
        token
      ).then(res => {
        console.log('Email success');
        setMsgSent(true);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  function getLabel() {
    if(isLoading)
      return '';
    if(msgSent)
      return 'Message sent';
    return 'Send message';
  }

  return (
    listing.length > 0 ?
    <div className={styles.ctn}>
      <div className={styles.exit}>
        <div style={{ cursor: 'pointer' }} onClick={(e) => p.setSelected(null)}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.media}>
          <ViewMedia 
            isSold={listing[0].is_sold ? true : false} 
            id={listing[0].id}
            media={
                listing.map(datum => 
                  datum.orderIndex != null ? `${SalesAPI.getMediaURL(datum.id, datum.name)}` as string : `${SalesAPI.getMediaPlaceholderURL()}`
                )
            }
          />
        </div>
        <div className={styles.text}>
          <div className={styles.title} style={{ marginBottom: '0.5em', fontWeight: 'bold' }}>{listing[0].title}</div>
          <div className={styles.title} style={{ color: 'green' }}>
            {`\$${listing[0].price.toLocaleString()}`}
            {listing[0].is_sold ? <span style={{ color: 'red' }}>{' • Sold'}</span> : null}
          </div>
          <div className={styles.snippet} style={{  }}>{`Last posted ${getLastPosted(parseInt(listing[0].id))}`} </div>
          <div style={{ marginTop: '1rem'}} className={styles.snippet}> <OdoIcon/> Driven {listing[0].odometer.toLocaleString()} KM</div>
          <div className={styles.snippet}> <CarIcon /> Body Type - {listing[0].body_type} </div>
            
          <div className={styles.snippet} style={{ margin: '2em 0 0.25em 0' }}> <DescIcon /> Vehicle Description </div>
          <div className={styles.desc} style={{ marginBottom: '2rem', flexGrow: 1, whiteSpace: 'pre-wrap' }}> 
            {listing[0].description ?? 'No description available.'}
          </div>
            <div className={styles.title} style={{ marginBottom: '0.5rem' }}> We're happy to help! </div>
            <div className={styles.snippet}> <PhoneIcon /> {data.sales_phone_number} </div>
            <div className={styles.snippet}> <MailIcon /> {data.email_address} </div>

            <div className={styles.snippet} style={{ marginTop: '1rem', marginBottom: '0.5rem' }}> <QuestionIcon /> Ask us a question </div>
            <form ref={formRef} style={{ cursor: msgSent || listing[0].is_sold ? 'not-allowed' : 'pointer' }} >
              <input className={styles.input} name={'email'} placeholder={'Your e-mail address'} type='email' required disabled={msgSent || listing[0].is_sold === 1}/>
              <input className={styles.input} name={'msg'} defaultValue={'Hello, is this still available?'} minLength={10} maxLength={150} required disabled={msgSent || listing[0].is_sold === 1}/>
              <Button label={getLabel()} onClick={onSubmit} 
                className={styles.btn}
                style={{ 
                  justifyContent: 'center', 
                  marginTop: '0.5rem', 
                  borderRadius: '5px', 
                  pointerEvents: msgSent || listing[0].is_sold ? 'none' : 'all',
                  backgroundImage: isLoading || msgSent ? 'linear-gradient(to right, green, darkgreen' : 'auto' }}>
                {isLoading && <LoadingSpinner color='black' />}
              </Button>
            </form>
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY ?? ''} size='invisible' ref={reRef}/>
        </div>
      </div>
    </div> : null
  );
}

function getLastPosted(ms: number) {
  var seconds = (new Date().getTime() - ms) / 1000;
  if(seconds < 60)
    return `about ${Math.floor(seconds)} seconds ago`;
  
  var minutes = seconds / 60;
  if(minutes < 60)
    return `about ${Math.floor(minutes)} minutes ago`;

  var hours = minutes / 60;
  if(hours < 24)
    return `about ${Math.floor(hours)} hours ago`;
  
  var days = hours / 24;
  if(days < 31)
    return `about ${Math.floor(days)} days ago`;

  return `on ${new Date(ms).toLocaleDateString()}`;
}

export default ListingModal;