import React, { useRef, useState, useEffect } from 'react';
import styles from './About.module.css';
import front from 'assets/about/front.webp';
import { Button } from 'modules/common';
import { RiDoubleQuotesL as MarksLeft } from 'react-icons/ri';
import { RiDoubleQuotesR as MarksRight } from 'react-icons/ri';
import { useGoogleMapsData } from 'remote';

export default function About() {
  const { data } = useGoogleMapsData();
  const quoteRef = useRef<HTMLDivElement>(null);
  const authorRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<number>(0);

  function appear() {
    if(!quoteRef.current || !authorRef.current)
      return;
    quoteRef.current.classList.add(styles.appear);
    authorRef.current.classList.add(styles.appear);
    setTimeout(() => {
      disappear();
    }, 5000)
  }

  function disappear() {
    if(!quoteRef.current || !authorRef.current)
      return;
    quoteRef.current.classList.remove(styles.appear);
    authorRef.current.classList.remove(styles.appear);
    setTimeout(() => {
     setActive(prev => (prev + 1) % data.reviews.length);
    }, 600)
  }


  useEffect(() => {
    appear();
  }, [active])

  return (
    <div style={{ width: '100%', height: 'fit-content', position: 'relative'}}>
      <div className={styles.clouds} />
      <div className={styles.ctn}>
        <hr className={styles.hr} />
        <div className={styles.content}>
          <img className={styles.front} src={front} />
          <div className={styles.reviews}>
            <div className={styles.text}>
              <div className={styles.marksLeft}>
                <MarksLeft />
              </div>
              <div className={styles.quote} ref={quoteRef}>
                {data.reviews[active].text} 
              </div>
              <div className={styles.marksRight}>
                <MarksRight />
              </div>
            </div>
            <div className={styles.credit}>
              <div className={styles.author} ref={authorRef}>
                Google Review by {data.reviews[active].author_name}
              </div>
              <Button className={styles.btn} label='See All Reviews' onClick={(e) => window.open(data.url, '_blank')}/>
            </div>
            <div className={styles.spheal}>
              <div>
                Our reputation is very important to us. 
                At Kieu Auto, you will always only get the 
                service you actually need, nothing more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}