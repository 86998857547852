import React from 'react';
import styles from './Button.module.css';

type props = {
  label: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

const Button: React.FC<props> = (p) => {
  return (
    <div onClick={p.onClick} className={`${styles.ctn} ${p.className}`} style={p.style}>
      {p.label}
      {React.Children.count(p.children) ?
        <div style={{ marginLeft: '0.5em' }}>
          {p.children}
        </div> : null
      }
    </div>
  );
}

export default Button;