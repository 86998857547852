import React, { useState } from 'react';
import styles from './Contact.module.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaClock } from 'react-icons/fa';
import { Button } from 'modules/common';
import { useGoogleMapsData } from 'remote';
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
}

export default function Contact() {
  const { data, isLoaded } = useGoogleMapsData();
  const [selected, setSelected] = useState<boolean>(true);
  return (
    <>
      <div className={styles.numbers}>
        <div className={styles.number}>
          <span className={styles.numberTitle}>Service</span>
          {data.service_phone_number}
        </div>
        <div className={styles.number}>
          <span className={styles.numberTitle}>Sales</span>
          {data.sales_phone_number}
        </div>
      </div>
      <div className={styles.ctn}>
        <div className={styles.banner} />
        <div className={styles.content}>
          <div className={styles.text}>
            <div className={styles.header}>Contact Us</div>
            <div className={styles.subheader}>We open early throughout the week, always happy to answer any of your questions.</div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoContent}>
              <div className={styles.infoHeader}>
                <FaPhoneAlt className={styles.infoIcon} />
                Phone & E-mail
              </div>
              <div> Service: {data.service_phone_number}</div>
              <div> Sales: {data.sales_phone_number}</div>
              <div> John's mobile: {data.mobile_phone_number} </div>
              <div> {data.email_address} </div>
            </div>
            <div className={`${styles.infoContent} ${styles.hours}`}>
              <div className={styles.infoHeader}>
                <FaClock className={styles.infoIcon} />
                Business Hours
                <div className={styles.infoStatus} style={{ fontFamily: 'RobotoR', fontSize: '0.8em', color: '#5e0000' }}>
                  We are currently {data.open ? 
                <span style={{color: 'green'}}>open</span> : <span style={{color: 'red'}}> closed </span>}
              </div>
              </div>
              {/* {data.weekday_text.map((day, i) => <div key={i}>{day}</div>)} */}
              <div>{data.formatted_hours[0]}</div>
              <div>{data.formatted_hours[1]}</div>
            </div>
            <div className={styles.infoContent}>
              <div className={styles.infoHeader}>
                <FaMapMarkerAlt className={styles.infoIcon} />
                Shop Address
              </div>
              <div> {data.address_components[0]} </div>
              <div> {data.address_components[1]} </div>
              <div> {data.address_components[2]} </div>
            </div>
          </div>
          <div className={styles.mapCtn}>
            {isLoaded ? 
            <GoogleMap 
              mapContainerClassName={styles.map} 
              zoom={14} 
              center={{ lat: data.lat, lng: data.lng }} 
              options={options}
              onClick={(e) => setSelected(false)}
            >
              <Marker 
                position={{ lat: data.lat, lng: data.lng }}
                onClick={(e) => {e.domEvent.preventDefault(); setSelected(true)}}
                label={'🔧'} 
              />
              {selected ? 
              <InfoWindow 
                position={{ lat: data.lat, lng: data.lng }} 
                options={{ disableAutoPan: true, pixelOffset: new window.google.maps.Size(0, -40)}} 
                onCloseClick={() => setSelected(false)}
              >
                <div className={styles.infoWindow}>
                  <div style={{ fontSize: '1rem'}}> {data.name} </div>
                  <div>
                    {data.address_components[0]}
                    <br/>
                    {data.address_components[1]}
                    <br/>
                    {data.address_components[2]}
                    <br/>
                  </div>
                  <a href={data.url} target='_blank' style={{ textDecoration: 'none' }}>View on Google Maps</a>
                </div>
              </InfoWindow> : null }
            </GoogleMap> : <div>Loading</div> }
          </div>
          <div className={styles.end}>
              <div className={styles.altHeader} style={{ textAlign: 'center' }}>Have feedback for us?</div>
              <div className={styles.subheader} style={{ textAlign: 'center' }}>
                Leave us a review - we'd like to hear from you! We're always looking to improve our customer service.
              </div>
              <Button 
                label='Review us on Google Maps' 
                onClick={(e) => window.open(data.url, '_blank')}
                />
          </div>
        </div>
      </div>
    </>
  );
}