import React, { useRef, useState, LegacyRef, useEffect, ReactElement, Children } from 'react';
import styles from './Carousel.module.css';

type DisplayProps = {
  children?: React.ReactNode;
}

const Carousel: React.FC<DisplayProps> = ( p ) => {
  const trackRef = useRef<HTMLDivElement>();
  const [selected, setSelected] = useState<number>(-1);
  const [order, setOrder] = useState<number[]>([]);
  const [exitTime, setExitTime] = useState<number>(new Date().getTime());
  const exitRef = useRef<number>(exitTime);
  exitRef.current = exitTime;
  
  // useEffect(() => {
  //   const DISPLAY_DURATION = 5000;
  //   const idle = () => { 
  //     setTimeout(() => {
  //       if(new Date().getTime() >= exitRef.current)
  //         // onSlide(true);
  //       idle();
  //     }, DISPLAY_DURATION)
  //   };

  //   // idle();
  // }, [])

  return (
    <div className={styles.ctn}>
      <div className={styles.display}>
          <img className={styles.slide} />
      </div>
    </div>
  );
}

export default Carousel;

