import React, { useContext, useEffect, useState, useRef } from 'react';
import { SalesAPI, ListingType } from 'remote'; 
import ReCAPTCHA from 'react-google-recaptcha';

type DataContextType = {
  isLoading: boolean;
  DataFunctions: {
    getThumbnails: () => ListingType[];
    getListingById: (id: string) => ListingType[];
    SortBySold: (arr: ListingType[]) => ListingType[];
    SortByPosted: (arr: ListingType[]) => ListingType[];
  }
}

const DataContext = React.createContext<DataContextType>({} as DataContextType);

interface ProviderProps {
  children?: React.ReactNode;
}

const DataProvider: React.FC<ProviderProps> = ( p ) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rawData, setRawData] = useState<ListingType[]>([]);
  const reRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const fetchData = async () => {
      if(!reRef.current)
        return;
      // const token = await reRef.current.executeAsync();
      // reRef.current.reset();
      // if(!token)
      //   return;
      const token = '';
      SalesAPI.getListings(token)
      .then(res => {
        console.log('Listing data:', res.data);
        setRawData(res.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.log('Failed to fetch listings:', err);
      })
    }
    fetchData();
  }, [])

  const getThumbnails = () => {
    if(isLoading)
      return [];
    
    return SortBySold(SortByPosted(rawData.filter((datum) => datum.orderIndex === 0 || !datum.orderIndex)));
  }

  function getListingById(id: string) {
    console.log('ordered',
      rawData.filter(datum => datum.id === id).sort((a: any, b: any ) => b.orderIndex - a.orderIndex)
    );

    return rawData.filter(datum => datum.id === id).sort((a: any, b: any ) => a.orderIndex - b.orderIndex);
  }

  const DataFunctions = {
    getThumbnails,
    getListingById,
    SortBySold,
    SortByPosted
  }

  return (
    <DataContext.Provider value={{ isLoading, DataFunctions }}>
      <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY ?? ''} size='invisible' ref={reRef}/>
      {p.children}
    </DataContext.Provider>
  );
}

function SortBySold(arr: ListingType[]) {
  return arr.sort((a, b) => { return a.is_sold - b.is_sold });
}

function SortByPosted(arr: ListingType[]) {
  return arr.sort((a, b) => { return parseInt(b.id) - parseInt(a.id) });
}

export default DataProvider;

export const useData = () => useContext(DataContext);