import React, { SetStateAction, useEffect } from 'react';
import { ListingType } from 'remote';
import styles from './Sales.module.css';
import { Grid, Button } from 'modules/common';
import { useNavigate } from 'react-router';

type Props = {
  featured: ListingType[];
  setSelected: React.Dispatch<SetStateAction<string | null>>;
};

export default function Sales(p: Props) {
  const navigate = useNavigate();
  
  useEffect(() => {
    console.log('Featured', p.featured);
  })

  return (
    <div className={styles.ctn}>
      <div className={styles.banner} />
      <div className={styles.innerCtn}>
        <div className={styles.texture} />
        <div className={styles.text}>
          <div className={styles.header}>Featured Vehicles</div>
          <div>Not only do we fix your vehicles, we sell ours at competitive prices too.</div>
        </div>
        <div className={styles.featured}>
          <Grid className={styles.grid} data={p.featured} setSelected={p.setSelected} />
        </div>
        <div className={styles.extra}>
          <div className={styles.filler}>All our vehicles come guaranteed with a safety inspection. Financing available.</div>
          <Button label='View Sales Inventory' className={styles.btn} onClick={(e) => navigate('/sales')} />
        </div>
      </div>
    </div>

  );
}