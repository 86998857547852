import React, { useEffect, useState } from 'react';
import { ListingType } from 'remote';
import styles from './Grid.module.css';
import Card from './Card/Card';

type Props = {
  data: ListingType[];
  className?: string;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function Grid(p: Props) {
  useEffect(() => {
    console.log('data', p.data);
  })
  return (
    <div className={styles.ctn + ' ' + p.className}>
      {p.data.map((datum, i) => {
        return <Card datum={datum} onClick={(e) => p.setSelected(datum.id)} />;
      })}
    </div>
  );
}
