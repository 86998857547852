import React, { useEffect, useState } from 'react';
import styles from './Grid.module.css';

type GridProps = {
  children?: React.ReactNode;
}

const Container: React.FC<GridProps> = ( p ) => {
  return (
    <div className={styles.ctn}>
      {p.children}
    </div>
  );
}

type ItemProps = {
  content: string;
  hoverContent?: string[];
  src?: string;
  pos?: string;
}

const Item: React.FC<ItemProps> = ( p ) => {
  const [active, setActive] = useState<boolean>(false);

  return (
    <>
      <div 
        className={styles.item} 
        style={{ 
          backgroundImage: `linear-gradient(to top right, #000000aa, #ff000099), url(${p.src})`,
          backgroundPosition: p.pos,
        }}
        onMouseEnter={(e) => setActive(true)}
        onMouseLeave={(e) => setActive(false)}
        >
        {active && p.hoverContent ? 
          p.hoverContent.map((str, i) => 
          (<div className={styles.hoverItem} key={i}>{`• ${str}`}</div>)) : p.content}
      </div>
    </>
  );
}

const ServiceGrid = {
  Container: Container,
  Item: Item
}

export default ServiceGrid;