import React from 'react';
import styles from './Services.module.css';
import { ServiceGrid } from 'modules/common';

import maintenance from 'assets/images/services/maintenance.webp';
import tires from 'assets/images/services/tires.webp';
import brakes from 'assets/images/services/brakes2.webp';

import safeties from 'assets/images/services/safeties.webp';
import alignments from 'assets/images/services/alignments.webp';
import suspension from 'assets/images/services/suspension.webp';

import ac from 'assets/images/services/AC.webp';
import electrical from 'assets/images/services/electrical.webp';
import engines from 'assets/images/services/engines.webp';
import transmissions from 'assets/images/services/transmissions2.webp';

export default function Services() {

  return (
    <>
    <div className={styles.ctn}>
      <div className={styles.banner} />
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.header}>Our Services</div>
          <div className={styles.subheader}>Our team of expert mechanics are highly-trained in repairing all-manner of vehicular issues, no matter how difficult or small.</div>
        </div>
        <div className={styles.grid}>
          
        <ServiceGrid.Container>
          <ServiceGrid.Item content='Maintenance' src={maintenance} pos='75% 50%' 
            hoverContent={['Oil & filter changes', 'Air & cabin filters', 'Wipers & washer fluid', 'Free inspections']}
            />
          <ServiceGrid.Item content='Tires' src={tires} pos='50% 50%' 
            hoverContent={['Rotations & swaps', 'Repairs & re-seals', 'Mount & balances', 'Sensor installations']}
            />
          <ServiceGrid.Item content='Brakes' src={brakes} pos='50% 50%' 
            hoverContent={['Brake pads & shoes', 'Rotors & drums', 'Calipers', 'Brake lines']}
            />
          <ServiceGrid.Item content='Safeties' src={safeties} pos='75% 50%' />
          <ServiceGrid.Item content='Steering' src={alignments} pos='50% 50%' 
            hoverContent={['Alignments', 'Power steering', 'Tie rods']}
            />
          <ServiceGrid.Item content='Suspension' src={suspension} pos='50% 50%' 
            hoverContent={['Shocks & struts', 'Ball joints', 'Control arms']}
            />
          <ServiceGrid.Item content='Heating & Cooling' src={ac}  pos='50% 50%'
            hoverContent={['AC recharges', 'Radiator flushes', 'Heater cores']}
            />

          <ServiceGrid.Item content='Electrical' src={electrical} pos='50% 50%' 
            hoverContent={['Battery replacements', 'Alternators', 'Diagnostics', 'Lights & dash']}
            />
          <ServiceGrid.Item content='Engines' src={engines} pos='50% 50%'
            hoverContent={['Rebuilds & replacements', 'Overheating & knocking repairs', 'Leak re-seals', 'Exhausts', 'Timing chains']}
            />
          <ServiceGrid.Item content='Drivetrain' src={transmissions} pos='30% 50%' 
            hoverContent={['Transmissions', 'Transfer cases', 'Differentials', 'Axles & shafts']}
            />
        </ServiceGrid.Container>
      </div>
      </div>
    </div>
    </>
  );
}