import axios from 'axios';

const host = process.env.REACT_APP_API_HOST;

const sendInquiry = async (msg: string, title: string, sender: string, token: string | null) => {
  if(!token)
    return;
  return axios({
    method: 'post',
    baseURL: host,
    url: '/sales/sendInquiry',
    params: {
      msg,
      title,
      sender,
      token
    }
  });
}

const getMediaPlaceholderURL = () => {
  // return `${host}/media/placeholder.png`;
  return "https://www.dropbox.com/s/wroox1pvjm030xv/placeholder.png?dl=0&raw=1";
}

const getMediaURL = (id: string, resource: string) => {
  // return `${host}/media/${id}/${resource}`;
  return resource;
}

const getListings = async (token: string) => {
  return axios({
    method: 'post', 
    baseURL: host, 
    url: '/sales/getListings',
    params: {
      token
    }
  });
};

const SalesAPI = {
  getMediaURL,
  getMediaPlaceholderURL,
  getListings,
  sendInquiry
};

export default SalesAPI;

