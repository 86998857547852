import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Home } from 'modules/home';
import { Inventory } from 'modules/inventory';
import Nav from './Nav/Nav';
import Footer from './Footer/Footer';
import ListingModal from './ListingModal/ListingModal';
import { GoogleMapsProvider, DataProvider } from 'remote';

function App() {
  const { pathname, hash, key } = useLocation();
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <DataProvider>
      <GoogleMapsProvider input={'Kieu Auto Service'}>
          <Nav />
          <Routes>
            <Route path='/' element={<Home setSelected={setSelected} />} />
            <Route path='/sales' element={<Inventory setSelected={setSelected}  />} />
            <Route path='*' element={<>404</>} />
          </Routes>
          <Footer />
          <ListingModal selected={selected} setSelected={setSelected} />
      </GoogleMapsProvider>
    </DataProvider>
  );
}

export default App;
