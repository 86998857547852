import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useData } from 'remote';
import styles from './Inventory.module.css';
import { ClipLoader as LoadingSpinner } from 'react-spinners';
import { ListingModal } from 'modules/common';
import { ListingType } from 'remote';
import { Grid } from 'modules/common';

type Props = {
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
}

const Inventory: React.FC<Props> = ( p: Props ) => {
  const { isLoading, DataFunctions } = useData();

  useEffect(() => {
    if(!isLoading)
      console.log(DataFunctions.getThumbnails());
  }, [isLoading])

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title> Sales | Kieu Auto Service </title>
      </Helmet>
      <div className={styles.ctn} style={{ height: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {isLoading ? 
          <LoadingSpinner size={150} /> : 
          <div className={styles.grid}>
            <Grid data={DataFunctions.SortBySold(DataFunctions.SortByPosted(DataFunctions.getThumbnails()))} setSelected={p.setSelected}></Grid>
          </div>
        }
      </div>
    </>
  );
}

export default Inventory;