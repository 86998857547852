import { ListingType, SalesAPI } from "remote";
import styles from './Card.module.css';
import { TaggedImage } from 'modules/common';

type Props = {
  datum: ListingType;
  onClick: (e: React.SyntheticEvent) => void;
}

export default function Card(p: Props) {

  return (
    <div className={styles.ctn} onClick={p.onClick}>
      <div className={styles.thumbnail}>
        <TaggedImage image={p.datum.name} isSold={p.datum.is_sold ? true : false}/>
      </div>
      <div className={styles.footer}>
        <div>{`${p.datum.year} ${p.datum.make} ${p.datum.model}`}</div>
        <div>{`\$${p.datum.price.toLocaleString()}`}</div>
      </div>
      <div className={styles.color}/>
    </div>
  );
}