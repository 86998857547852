import React, { useState, useRef, useEffect, useCallback, SyntheticEvent } from 'react';
import styles from './ViewMedia.module.css';
import { TaggedImage } from 'modules/common';
import { FaChevronLeft as ArrowLeft } from 'react-icons/fa';
import { FaChevronRight as ArrowRight } from 'react-icons/fa';
import { SalesAPI } from 'remote';

type Props = {
  id?: string;
  media: Array<string>;
  isSold: boolean;
};

const ViewMedia: React.FC<Props> = ( p ) => {
  const [focusIdx, setFocusIdx] = useState<number>(0);


  console.log('media', p.media);
  const handleFocus = (i: number) => {
    setFocusIdx(i);
  };

  return (
    <div className={styles.ctn}>
      <div className={styles.focusedImageCtn}>
        <div className={styles.focusedImageBg} style={{ backgroundImage: p.media.length ? `url(${p.media[focusIdx]})` : '' }}/>
        <div className={styles.focusedImage}>
            <div className={styles.focusedImageBtnLeft} onClick={(e) => setFocusIdx(prev => (prev + p.media.length - 1) % p.media.length)}><ArrowLeft /></div>
            <div className={styles.focusedImageBtnRight} onClick={(e) => setFocusIdx(prev => (prev + 1) % p.media.length)}><ArrowRight /></div>
            <div style={{ backgroundImage: `url(${p.media.length ? p.media[focusIdx] : ''})`, backgroundSize: 'contain', width: '100%', height: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
        </div>
        <div className={styles.imageReel}>
          {p.media.map((m, i) => {
            return (
              <div key={i} className={styles.reelImage + ' ' + (i === focusIdx ? styles.focusedReelItem : '') }>
                <TaggedImage noBg image={m} size='cover' onClick={(e) => handleFocus(i)} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ViewMedia;