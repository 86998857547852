import React, { useContext, useEffect } from 'react';
import styles from './Footer.module.css';
import { useGoogleMapsData } from 'remote';
import { FaFacebook } from 'react-icons/fa';

type FooterProps = {}

const Footer: React.FC<FooterProps> = ( p ) => {
  const { data, isLoaded } = useGoogleMapsData();

  return (
    <div className={styles.ctn}>
      <div className={styles.content}>
        <div className={`${styles.column} ${styles.contact}`} style={{ justifyContent: 'space-between'}}>
          <div>
            <div className={styles.title}>Contact Us</div>
            <div className={styles.list}>
              <div>{data.physical_address[0]}</div>
              <div>{data.physical_address[1]}</div>
              <div>Service - {data.service_phone_number}</div>
              <div>Sales - {data.service_phone_number}</div>
              <div>{data.email_address}</div>
              <a 
                style={{display: 'flex', alignItems: 'center', width: 'fit-content'}} 
                href={data.facebook_url} 
                target='_blank'
              >
                <FaFacebook fill={'white'} size={'1.5em'}/>
              </a>
            </div>
          </div>
          <div>
            <div className={styles.title}>
              Website Inquiries
            </div>
            <div> Developed in-house</div>
            <div>© 2023 Kieu Auto Service</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Services</div>
          <div className={styles.list}>
            {data.services.map((service, i) => (
              <div key={i}>{service}</div>
            ))}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>Business Hours</div>     
          <div className={styles.list}>
            {data.hours.map((time, i) => (
              <div key={i}>{time}</div>
            ))}
          </div>     
        </div>
      </div>
    </div>
  );
}

export default Footer;