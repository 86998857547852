import React, { SetStateAction, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Carousel from './Carousel/Carousel';
import Sales from './Sales/Sales';
import About from './About/About';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import { useData } from 'remote';

import styles from './Home.module.css';
import { useNavigate } from 'react-router';


type Props = {
  setSelected: React.Dispatch<SetStateAction<string | null>>;
}

function Home(p: Props) {
  const nav = useNavigate();
  const data = useData();
  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title> Home | Kieu Auto Service </title>
      </Helmet>
      <div className={styles.ctn}>
        <Carousel />
        <Sales featured={data.DataFunctions.getThumbnails().splice(0, 4)} setSelected={p.setSelected} />
        <About />
        <Services />
        <Contact />
      </div>
    </>
  );
}

export default Home;
